<template>
  <div>
    <div class="container">

      <div class="descContainer">
        <div class="content contentRev">
          <slot name="revContentTitleH3"><h3>国际小件物流</h3></slot>
          <slot name="revContentTitleH1"><h1>跨境电商解决方案</h1></slot>
          <slot name="revContentSpan">
              <span>依托全球物流服务网络及国国内外多家快递公司航空公司的长期合作关系，为当前电商企业进出口端口的分发，采购，仓储、清关等全方位服务，减轻电商企业在物流、清关、税务上相关成本，提升整个物流的低成本、高时效、便捷的服务。</span>
            </slot>
        </div>
        <div class="imgDiv imgDivRev">
          <div class="imgDiv imgDivA">
            <slot name="revImglable"><img src="../../../assets/images/contentImgs/banner-retailers.svg"/></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@import url('../../../assets/css/imgdes.less');
</style>