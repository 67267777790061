<template>
  <div class="container">
    <div class="banner">
      <img src="../assets/images/contentImgs/banner-logistics.svg" />
    </div>
    <div class="innerBox">
        <div class="desc">
          <h1>- 物流服务介绍 -</h1>
          <img src="../assets/images/contentImgs/conetent-desc.svg" />
        </div>
    </div>
    <div class="innerBox">
      <div class="desc ldes">
        <ldes-rimg>
          <h3 slot="revContentTitleH3"></h3>
          <h1 slot="revContentTitleH1">物流服务</h1>
          <span slot="revContentSpan"
            >提供最新国际国内空运价格,国际货运代理,航空公司在线查询等信息,打造国内国际空运价格等一站式物流服务，我们有专业的操作人员和成熟的内部管理系统，从而确保了货物操作的准确性和安全性。部分国家航空货运支持门到门派送。根据客户不同时效和价格服务要求，我们提供相对应的解决方案来满足不同客户的需求。</span
          >
          <img
            slot="revImglable" class="img"
            src="../assets/images/contentImgs/world_import.png"
          />
        </ldes-rimg>
      </div>
    </div>
  </div>
</template>
<script>
import LdesRimg from "../components/plugin/imgdesc/ldes-rimg.vue";
export default {
  data() {
    return {};
  },
  components: { LdesRimg },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/banner.less");
@import url("../assets/css/container.less");
.banner {
  // background-color: #4479a9 !important;
  background-image:linear-gradient(180deg,#628fa3 0%,#3e545d 51%,#4c281f 100%);
 
  img {
    height: 100% !important;
  }
}

.ldes {
  h1 {
    text-align: left;
  }
}
.img{
  width: 500px !important;
}
</style>